<template>
  <v-tabs v-model="tab" icons-and-text>
    <v-tabs-slider color="purple darken-4"></v-tabs-slider>
    <v-tab v-for="(tab, i) in tabs" :key="i">
      <v-icon large>{{ tab.icon }}</v-icon>
      <div class="caption py-1">{{ tab.name }}</div>
    </v-tab>
    <v-tab-item>
      <inventory-list />
    </v-tab-item>
    <v-tab-item>
      <buy-requests-list />
    </v-tab-item>
    <v-tab-item>
      <lots-list />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import InventoryList from '@/components/Home/Tabs/Inventory'
import BuyRequestsList from '@/components/User/Inventory/Tabs/BuyRequestsList'
import LotsList from '@/components/User/Inventory/Tabs/LotsList'

export default {
  name: 'InventoryTabs',
  components: {
    InventoryList,
    BuyRequestsList,
    LotsList
  },
  computed: {
    tabs () {
      return [
        { name: this.$t('inventory'), icon: 'mdi-home-automation' },
        { name: this.$t('buy_requests'), icon: 'mdi-account-outline' },
        { name: this.$t('on_sale'), icon: 'mdi-account-outline' }
      ]
    }
  },
  data: () => ({
    tab: 0
  })
}
</script>
