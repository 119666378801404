<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        {{ $t('user_sell_modal_title', { name: item.name }) }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="modal-text">
                {{ $t('user_sell_modal_text') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <qty-selector v-model="qty_for_sale" :label="$t('user_sell_modal_qty_field')" :min="item.minimum_saleable" :max="item.saleable_qty" />
            </v-col>
          </v-row>
          <v-divider class="divider mb-4" />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="closeDialog">
          {{ $t('close') }}
        </v-btn>
        <v-btn color="primary" text @click="submit">
          {{ $t('user_sell_modal_sell_button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import QtySelector from '@/components/Core/Form/QtySelector'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SellItemModal',
  components: {
    QtySelector
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opened: false,
      qty_for_sale: this.item.saleable_qty
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      sell: 'user/inventory/sellItem'
    }),
    submit () {
      this.sell({
        token: this.token,
        payload: {
          inventory_id: this.item.id,
          qty: this.qty_for_sale
        }
      })
      this.closeDialog()
    },
    openDialog () {
      this.opened = true
    },
    closeDialog () {
      this.opened = false
      this.qty_for_sale = 0
    }
  }
}
</script>
