<template>
  <v-row class="text-center inventory-grid">
    <template v-if="!loading && inventoryItems.length">
      <v-col v-for="item in inventoryItems" :key="item.id" cols="12" sm="12" md="6" lg="4" xl="3">
        <list-inventory-item :item="item" />
      </v-col>
    </template>
    <v-col v-else-if="loading">
      <loader in-block />
    </v-col>
    <v-col v-else>
      <div>
        {{ $t('no_inventory_items_found') }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import ListInventoryItem from '@/components/User/Inventory/Item'
import Loader from '@/components/Core/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InventoryTab',
  components: {
    ListInventoryItem,
    Loader
  },
  async mounted () {
    await this.loadInventory(this.token)
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      inventoryItems: 'user/inventory/getInventory'
    })
  },
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions({
      loadInventory: 'user/inventory/loadInventory'
    })
  }
}
</script>
<style scoped>
.inventory-grid {
  margin: 50px 30px;
}
</style>
