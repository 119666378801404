<template>
  <v-card class="inventory-item-card mx-auto">
    <v-img contain height="250" :src="item.current_stage.image_url" />

    <v-card-title>
      {{ item.name }}
      <span class="text-right price">${{ item.current_price_per_unit }}</span>
    </v-card-title>

    <v-btn class="show-description" icon @click="showSpecification = !showSpecification">
      <v-icon color="#0FD283">{{ showSpecification ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>

    <v-expand-transition>
      <div v-show="showSpecification">
        <v-card-text>
          <v-chip-group column>
            <div class="specification-item">
              <span>В теплиці </span>
              <v-chip>{{ item.qty }} шт.</v-chip>
            </div>
            <div class="specification-item">
              <span>Висаджено </span>
              <v-chip>{{ item.landing_date }}</v-chip>
            </div>
            <div class="specification-item">
              <span>Дохід </span>
              <v-chip>${{ item.total_earnings }}</v-chip>
            </div>
            <div class="specification-item">
              <span>Поточна вартість </span>
              <v-chip>${{ item.total_current_price }}</v-chip>
            </div>
            <div class="specification-item" v-if="item.final_growing_date">
              <span>Дозріє </span>
              <v-chip>{{ item.final_growing_date }}</v-chip>
            </div>
            <div class="specification-item" v-if="item.final_price > 0">
              <span>Максимальна вартість </span>
              <v-chip>{{ item.final_price }}.шт.</v-chip>
            </div>
          </v-chip-group>
        </v-card-text>
      </div>
    </v-expand-transition>

    <v-divider class="divider mx-4" />

    <v-card-actions class="text-right">
      <template v-if="item.is_saleable">
        <v-btn @click="sellClick" color="primary" text>
          <v-icon class="pr-2">mdi-store-clock</v-icon>
          {{ $t('to_sell') }}
        </v-btn>
        <v-btn color="red" text>
          <v-icon class="pr-2">mdi-sale</v-icon>
          {{ $t('to_sell_to_grower') }}
        </v-btn>
      </template>
      <template v-else>
        This item is not saleable! It's already set for sale
        <v-btn @click="cancelSale" color="red" elevation="3">Cancel</v-btn>
      </template>
    </v-card-actions>
    <sell-item :item="item" ref="sellItemDialog" />
    <cancel-sale :item="item" ref="cancelSaleDialog" />
  </v-card>
</template>
<script>
import SellItem from '@/components/User/Inventory/Modals/SellItem'
import CancelSale from '@/components/User/Inventory/Modals/CancelSale'

export default {
  name: 'ListInventoryItem',
  components: {
    SellItem,
    CancelSale
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showSpecification: false
  }),
  methods: {
    sellClick () {
      this.$refs.sellItemDialog.openDialog()
    },
    cancelSale () {
      this.$refs.cancelSaleDialog.openDialog()
    }
  }
}
</script>
<style lang="scss">
.inventory-item-card {
  border: 1px solid #BDE5DD!important;
  border-radius: 10px!important;

  & .v-card__title {
    font-size: 16px;

    & .price {
      flex: 1;
      font-weight: 700;
      color: #0BCA7C;
    }
  }

  & .v-slide-group__content {
    flex-direction: column;
    align-items: baseline;
  }

  & .specification-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  & .v-chip {
    border: 2px solid #FFFFFF!important;
    background: #0FD283!important;
    color: #FFFFFF!important;
    font-weight: 700;
    text-align: right;
  }

  & .v-card__actions {
    justify-content: space-between;
  }
}
</style>
