<template>
  <v-row class="qty-selector">
    <v-col cols="12" sm="4" class="label-column">
      <div class="label">
        {{ label }}
      </div>
    </v-col>
    <v-col cols="12" sm="2">
      <div @click="minus" class="minus">
        <span>-</span>
      </div>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field name="qty" type="number" :value="value" @change="changed" @input="changed" outlined autofocus />
    </v-col>
    <v-col cols="12" sm="2">
      <div @click="plus" class="plus">
        <span>+</span>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'QtySelector',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    }
  },
  methods: {
    changed (newValue) {
      if (newValue < this.min) {
        newValue = this.min
      }
      if (newValue > this.max) {
        newValue = this.max
      }
      this.$emit('input', parseInt(newValue))
    },
    minus () {
      this.changed(this.value - 1)
    },
    plus () {
      this.changed(this.value + 1)
    }
  }
}
</script>
<style lang="scss">
.qty-selector {
  margin: 10px 0;

  & .label-column {
    position: relative;
    top: 20px;
  }

  & .label {
    font-size: 18px;
    color: #649187;
  }

  & .minus, & .plus {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 2px solid #009357;
    border-radius: 10px;
    color: #009357;
    margin: auto;
    text-align: center;

    &:hover {
      color: #FFFFFF;
      background-color: #009357;
    }

    & span {
      font-size: 32px;
      position: relative;
      top: 15px;
    }
  }

  & .v-input__slot {
    min-height: 60px!important;
  }

  & .v-input input {
    text-align: center;
    font-size: 24px!important;
  }
}
</style>