<template>
  <v-row class="text-center buy-requests-grid">
    <template v-if="!loading && requests.length">
      <v-col v-for="request in requests" :key="request.id" cols="12" sm="12" md="6" lg="4" xl="3">
        <buy-request-item :key="request.id" :item="request" />
      </v-col>
    </template>
    <v-col v-else-if="loading">
      <loader in-block />
    </v-col>
    <v-col v-else>
      <div>
        {{ $t('no_buy_requests_found') }}
      </div>
    </v-col>
    <create-buy-request ref="createBuyRequestDialog" />
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import BuyRequestItem from '@/components/Auction/BuyRequestItem'
import CreateBuyRequest from '@/components/Auction/Modals/CreateBuyRequest'
import Loader from '@/components/Core/Loader'

export default {
  name: 'BuyRequestsListTab',
  components: {
    BuyRequestItem,
    CreateBuyRequest,
    Loader
  },
  async mounted () {
    await this.loadBuyRequests()
      .then(result => {
        this.requests = result.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  data: () => ({
    loading: true,
    requests: []
  }),
  methods: {
    ...mapActions({
      loadBuyRequests: 'user/buy_request/loadBuyRequests'
    }),
    openBuyRequestModal () {
      this.$refs.createBuyRequestDialog.openDialog()
    }
  }
}
</script>
<style scoped>
.buy-requests-grid {
  margin: 50px 30px;
}
</style>