<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-card>
          <v-list three-line v-if="inventoryItems.length > 0">
            <template v-for="(item, index) in inventoryItems">
              <list-inventory-item :key="item.id" :item="item" />
              <v-divider :key="index" :inset="item.inset" />
            </template>
          </v-list>
          <template v-else>
            <p class="subheading font-weight-regular">
              You have no lots created yet! <br />
              <router-link :to="'/trade/sell'">auction</router-link>
            </p>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ListInventoryItem from '@/components/User/Inventory/Item'

export default {
  name: 'LotsListTab',
  components: {
    ListInventoryItem
  },
  async mounted () {
    await this.loadLotsList(this.token)
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      inventoryItems: 'user/inventory/getLots'
    })
  },
  methods: {
    ...mapActions({
      loadLotsList: 'user/inventory/loadLotsList'
    })
  },
}
</script>
