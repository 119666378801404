<template>
  <v-container>
    <v-row class="text-center">
      <tabs />
    </v-row>
  </v-container>
</template>
<script>
import Tabs from '@/components/User/Inventory/Tabs'

export default {
  name: 'Inventory',
  components: {
    Tabs
  }
}
</script>
