<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Cancel Inventory Item Sale</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Selling of the item will be canceled
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Cancel Sale
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CancelSaleModal',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opened: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      cancel: 'auction/cancel'
    }),
    submit () {
      this.cancel({
        token: this.token,
        payload: {
          lot_id: this.item.lot_id
        }
      })
      this.closeDialog()
    },
    openDialog () {
      this.opened = true
    },
    closeDialog () {
      this.opened = false
    }
  }
}
</script>
